import { languages } from '../../common/languages'
import React, { useEffect, useState } from 'react';
import { RP } from '../../components/FacebookPixel';
import '../../components/TikTokPixel.js';
import _TN from '../../locale/Package/translator.js';
import saudiFunds from '../../assets/images/saudi-funds.png'
import mainLogo from "../../assets/images/logo-vertical.png"
import riyadhBankLogo from "../../assets/images/riyadh-bank.png"
import plusIcon from "../../assets/images/plus-icon.png"
import checkIcon from "../../assets/images/login-logo.png";
import "../../assets/css/--page-package.css";
import axios from 'axios';

function Package({ language }) {
    const [value,setValue] = useState({ name: "", phone: "", city: "", package: { one: false, two: false, three: false, four: false, five: false, six: false, seven: false, eight: false, nine: false }, step: 0 })
    const [error,setError] = useState({ name: "", phone: "", city: "", package: "" })
    const [submissionCompleted, setSubmissionCompleted] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        RP.pageView();
        window.scrollTo({ top: 0 });
        let direction = languages[language].direction
        if (direction === "RTL") {
            document.getElementsByTagName("body")[0].classList.add("RTL");
        } else {
            document.getElementsByTagName("body")[0].classList.remove("RTL");
        }
    }, [])

    const validateForm = (step) => {
        setLoading(true);
        if(step === 0){
            let flag = false;
            let err = { name: "", phone: "", city: "", package: "" };
            if(!value.name || value.name.length < 2){
                err.name = _TN("Please provide a valid name");
                flag = true;
            }
            if(!value.phone || value.phone.length < 10){
                err.phone = _TN("Please provide a valid mobile number");
                flag = true;
            }
            if(!value.city || value.city.length < 3){
                err.city = _TN("Please provide a valid city");
                flag = true;
            }
            setError(err);
            if(!flag){
                setLoading(false);
                setValue((prev)=>({...prev, step: 1}))
            }
            else{
                setLoading(false);
            }
        }
        if(step === 1){
            let flag = false;
            let err = { name: "", phone: "", city: "", package: "" };
            if(!value.package.one && !value.package.two && !value.package.three && !value.package.four && !value.package.five && !value.package.six && !value.package.seven){
                err.package = _TN("Please select at least one package");
                flag = true;
            }
            setError(err);
            if(!flag){
                submitForm();
            }
            else{
                setLoading(false);
            }

        }
    }

    const submitForm = () => {
        let request = {
            name: value.name,
            mobile: value.phone,
            city: value.city,
            locale: language,
            package_table: '',
            Package_details: []
        }
        let packages = [];
        let i = 1;
        let html = `<table style='width: 100%;height: 100%; padding: 10px 0; border-collapse: collapse; margin-bottom: 60px;'><thead><tr><th class='color-dark' style='padding: 5px; border: 1px solid #242424; text-align: center; font-size: 16px; margin: 0; font-weight: 400;'>#</th><th class='color-dark' style='padding: 5px; border: 1px solid #242424; text-align: center; font-size: 16px; margin: 0; font-weight: 400;'>الباقات</th><th class='color-dark' style='padding: 5px; border: 1px solid #242424; text-align: center; font-size: 16px; margin: 0; font-weight: 400;'>الفئة</th></tr></thead><tbody>`;
        if(value.package && value.package.one){
            packages.push({ package_name: "300 "+_TN("Umrah"), Umrah_via_package: `none`,  martyrs_invitation: `none`,  price: `0` })
            html += createHTMLRow(i,_TN("Our Soldiers"),300);
            i++;
        }
        if(value.package && value.package.two){
            packages.push({ package_name: "600 "+_TN("Umrah"), Umrah_via_package: `none`,  martyrs_invitation: `none`,  price: `0` })
            html += createHTMLRow(i,_TN("Our Soldiers"),600);
            i++;
        }
        if(value.package && value.package.three){
            packages.push({ package_name: "900 "+_TN("Umrah"), Umrah_via_package: `none`,  martyrs_invitation: `none`,  price: `0` })
            html += createHTMLRow(i,_TN("Our Soldiers"),900);
            i++;
        }
        if(value.package && value.package.four){
            packages.push({ package_name: "1500 "+_TN("Umrah"), Umrah_via_package: `none`,  martyrs_invitation: `none`,  price: `0` })
            html += createHTMLRow(i,_TN("Our Heroes"),1500);
            i++;
        }
        if(value.package && value.package.five){
            packages.push({ package_name: "2000 "+_TN("Umrah"), Umrah_via_package: `none`,  martyrs_invitation: `none`,  price: `0` })
            html += createHTMLRow(i,_TN("Our Heroes"),2000);
            i++;
        }
        if(value.package && value.package.six){
            packages.push({ package_name: "2500 "+_TN("Umrah"), Umrah_via_package: `none`,  martyrs_invitation: `none`,  price: `0` })
            html += createHTMLRow(i,_TN("Our Heroes"),2500);
            i++;
        }
        if(value.package && value.package.seven){
            packages.push({ package_name: "3000 "+_TN("Umrah"), Umrah_via_package: `none`,  martyrs_invitation: `none`,  price: `0` })
            html += createHTMLRow(i,_TN("The Brave"),3000);
            i++;
        }
        if(value.package && value.package.eight){
            packages.push({ package_name: "4000 "+_TN("Umrah"), Umrah_via_package: `none`,  martyrs_invitation: `none`,  price: `0` })
            html += createHTMLRow(i,_TN("The Brave"),4000);
            i++;
        }
        if(value.package && value.package.nine){
            packages.push({ package_name: "5000 "+_TN("Umrah"), Umrah_via_package: `none`,  martyrs_invitation: `none`,  price: `0` })
            html += createHTMLRow(i,_TN("The Brave"),5000);
            i++;
        }
        html += `</tbody></table>`;
        request.package_table = html;
        request.Package_details = packages;
        axios.post(`${process.env.REACT_APP_API_BASEURL}admin/package-selection`,{...request}).then((response) => {
            if (response?.data.status) {
                setSubmissionCompleted(true);
            }
            else{
                setError((prev)=>({...prev, package: _TN("Something went wrong. Please try again!")}));
                setLoading(false);
            }
        }).catch((e)=>{
            setError((prev)=>({...prev, package: _TN("Something went wrong. Please try again!")}));
            setLoading(false);
        })
    }

    const createHTMLRow = (i,category,value) => {
        return `<tr>
            <td class='color-light' style='padding: 5px; border: 1px solid #242424; text-align: center; font-size: 16px; margin: 0; font-weight: 400;'>${i}</td>
            <td class='color-light' style='padding: 5px; border: 1px solid #242424; text-align: center; font-size: 16px; margin: 0; font-weight: 400;'>${category}</td>
            <td class='color-light' style='padding: 5px; border: 1px solid #242424; text-align: center; font-size: 16px; margin: 0; font-weight: 400; ${language === 'ar' ? 'direction: rtl;' : ''}'>${value} ${_TN('Umrah')}</td>
        </tr>`;
    }

    return (
        <section id='package'>
                <form id='publicPackageForm'>
                    {
                        value.step === 0 ?
                        <div className='form-section section-one ltr'>
                            <div className='wrapper'>
                                <div className='section-container'>
                                    <div className='section-header'>
                                        <div className='form-logo'>
                                            <img src={saudiFunds} height={140} alt=''/>
                                            <img src={mainLogo} height={140} alt=''/>
                                        </div>
                                        <div className='section-headings'>
                                            <h1 className='ibm-plex-sans-arabic-semibold'>{_TN("Tawkeel Initiative for Umrah Al Badal")}</h1>
                                            <h2 className='ibm-plex-sans-arabic-medium'>{_TN("For the beneficiaries of the Fund for Martyrs, Wounded, Pow, and Missing in Action")}</h2>
                                        </div>
                                    </div>
                                    <div className='section-form-groups rtl'>
                                        <div className='form-group'>
                                            <h4 className='ibm-plex-sans-arabic-medium mt-0 text-left'>{_TN("Name")} <span className='text-danger'>*</span></h4>
                                            <input className='form-input text-left' value={value.name} onChange={(e)=>{ setValue((prev)=>({ ...prev, name: e.target.value.slice(0,50) })) }}/>
                                            { error.name ? <p className='mt-1 mb-0 text-danger text-left'>{error.name}</p> : null }
                                        </div>
                                        <div className='form-group'>
                                            <h4 className='ibm-plex-sans-arabic-medium mt-0 text-left'>{_TN("Mobile Number")} <span className='text-danger'>*</span></h4>
                                            <input className='form-input text-left' value={value.phone} onChange={(e)=>{ setValue((prev)=>({ ...prev, phone: e.target.value.slice(0,15) })) }}/>
                                            { error.phone ? <p className='mt-1 mb-0 text-danger text-left'>{error.phone}</p> : null }
                                        </div>
                                        <div className='form-group'>
                                            <h4 className='ibm-plex-sans-arabic-medium mt-0 text-left'>{_TN("City")} <span className='text-danger'>*</span></h4>
                                            <input className='form-input text-left' value={value.city} onChange={(e)=>{ setValue((prev)=>({ ...prev, city: e.target.value.slice(0,50) })) }}/>
                                            { error.city ? <p className='mt-1 mb-0 text-danger text-left'>{error.city}</p> : null }
                                        </div>
                                        <button type='button' disabled={loading} onClick={()=>{ validateForm(0) }} className='btn btn-dark mt-4'>{_TN("Next")}</button>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <div className='form-section section-two'>
                            <div className='wrapper'>
                                <div className='section-container'>
                                    <div className='section-header'>
                                        <div className='section-headings'>
                                            <h1 className='ibm-plex-sans-arabic-bold'>{_TN("Tawkeel Initiative for Umrah Al Badal")}</h1>
                                            <h2 className='ibm-plex-sans-arabic-medium'>{_TN("For the beneficiaries of the Fund for Martyrs, Wounded, Pow, and Missing in Action")}</h2>
                                        </div>
                                        <div className='form-logo'>
                                            <img src={mainLogo} height={130} alt=''/>
                                            <img src={saudiFunds} height={130} alt=''/>
                                        </div>
                                    </div>
                                    <div className='section-body'>

                                    {
                                        submissionCompleted ?
                                        <p className='text-center'>{_TN("Your request has been submitted. We will contact you shortly.")}</p> :
                                        <React.Fragment>
                                            <div className='form-packages'>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th className='text-center' rowSpan={3} style={{width: "60px", padding: "20px"}}>
                                                                <div className='text-orientation-vertical'>
                                                                    {_TN('Category')}
                                                                </div>
                                                            </th>
                                                            <td>{_TN('Our Soldiers')}</td>
                                                            <td>
                                                                <div className='d-flex align-items-center'> 
                                                                300 {_TN('Umrah')}
                                                                {
                                                                    value.package.one ?
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={checkIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, one: false} })) }}/> </div>:
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={plusIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, one: true} })) }}/> </div>
                                                                }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='d-flex align-items-center'> 
                                                                600 {_TN('Umrah')}
                                                                {
                                                                    value.package.two ?
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={checkIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, two: false} })) }}/> </div>:
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={plusIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, two: true} })) }}/> </div>
                                                                }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='d-flex align-items-center'> 
                                                                900 {_TN('Umrah')}
                                                                {
                                                                    value.package.three ?
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={checkIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, three: false} })) }}/> </div>:
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={plusIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, three: true} })) }}/> </div>
                                                                }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{_TN('Our Heroes')}</td>
                                                            <td>
                                                                <div className='d-flex align-items-center'> 
                                                                1500 {_TN('Umrah')}
                                                                {
                                                                    value.package.four ?
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={checkIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, four: false} })) }}/> </div>:
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={plusIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, four: true} })) }}/> </div>
                                                                }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='d-flex align-items-center'> 
                                                                2000 {_TN('Umrah')}
                                                                {
                                                                    value.package.five ?
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={checkIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, five: false} })) }}/> </div>:
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={plusIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, five: true} })) }}/> </div>
                                                                }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='d-flex align-items-center'> 
                                                                2500 {_TN('Umrah')}
                                                                {
                                                                    value.package.six ?
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={checkIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, six: false} })) }}/> </div>:
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={plusIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, six: true} })) }}/> </div>
                                                                }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{_TN('The Brave')}</td>
                                                            <td>
                                                                <div className='d-flex align-items-center'> 
                                                                3000 {_TN('Umrah')}
                                                                {
                                                                    value.package.seven ?
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={checkIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, seven: false} })) }}/> </div>:
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={plusIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, seven: true} })) }}/> </div>
                                                                }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='d-flex align-items-center'> 
                                                                4000 {_TN('Umrah')}
                                                                {
                                                                    value.package.eight ?
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={checkIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, eight: false} })) }}/> </div>:
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={plusIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, eight: true} })) }}/> </div>
                                                                }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='d-flex align-items-center'> 
                                                                5000 {_TN('Umrah')}
                                                                {
                                                                    value.package.nine ?
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={checkIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, nine: false} })) }}/> </div>:
                                                                    <div className='d-flex align-items-center justify-center ml-2 mr-2'><img className='cursor-pointer' width={28} src={plusIcon} onClick={()=>{ setValue((prev)=>({ ...prev, package: {...prev.package, nine: true} })) }}/> </div>
                                                                }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                { error.package ? <p className='mt-1 mb-4 text-danger text-left'>{error.package}</p> : null }
                                            </div>
                                            <p className='account-heading'>{_TN("TAWKEEL COMPANY FOR COMMERCIAL SERVICES")}</p>
                                            <div className='account-details'>
                                                <img src={riyadhBankLogo} width={200} alt=''/>
                                                <div>
                                                    <h4 className='section-label ltr'>{_TN("Current Account No:")}</h4>
                                                    <h4 className='section-data m-0'>1360222979940</h4>
                                                </div>
                                                <div>
                                                    <h4 className='section-label ltr'>{_TN("Unified Account Number (IBAN):")}</h4>
                                                    <h4 className='section-data m-0'>SA4620000001360222979940</h4>
                                                </div>
                                                <button type='button' disabled={loading} onClick={()=>{ validateForm(1) }} className='btn btn-dark'>{_TN("Send")}</button>
                                            </div>

                                        </React.Fragment>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </form>
        </section>
    )
}

export default Package;