import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import Navbar from "../../components/NavbarInner";
import Footer from "../../components/FooterInner";
import Hero from "./Hero";
import { languages } from '../../common/languages'
import "../../assets/css/privacy-policy.css";
import _T from '../../locale/PrivacyPolicy/translator';
import { RP } from '../../components/FacebookPixel';
import '../../components/TikTokPixel.js';


function createList() {
  let points = document.getElementsByClassName("secondary-heading-alt");
  let sidebar = document.getElementsByClassName("policy-siderbar-container")[0];
  if (sidebar) {
    sidebar.innerHTML = ""
  }
  for (let i = 0; i < points.length; i++) {
    let heading = points[i];
    heading.setAttribute("id", "section_pointer_" + i);
    const node = document.createElement("li");
    const anchor = document.createElement("a");
    node.setAttribute("data-number", i + 1);
    anchor.setAttribute("href", "#");
    anchor.setAttribute("data-pointer", "section_pointer_" + i);
    anchor.addEventListener("click", sidebar_navigation);
    const textnode = document.createTextNode(heading.textContent.replace(/\d+\.|^\s+|\s$/g, ''));
    anchor.appendChild(textnode);
    node.appendChild(anchor);
    sidebar.appendChild(node);
  }
}

function sidebar_navigation(e) {
  e.preventDefault()
  let id = e.target.attributes["data-pointer"].nodeValue;
  let offset = document.getElementById(id).offsetTop - 50;
  window.scrollTo({ top: offset, behavior: 'smooth' });
}

function Index({ language }) {
  const [search, setSearch] = useSearchParams();
  const [showHeader, setShowHeader] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  
  useEffect(() => {
    createList();
    const element = document.getElementById("seekerPolicy");
    let pos = element.offsetTop;
    window.scrollTo({ top: pos, behavior: 'smooth' });
    RP.pageView();

    window.scrollTo({ top: 0 });
    let direction = languages[language].direction
    if (direction === "RTL") {
      document.getElementsByTagName("body")[0].classList.add("RTL");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("RTL");
    }

    if(search){
      if(search.get('header') && search.get('header') === "hide"){
        setShowHeader(false);
      }
      else{
        setShowHeader(true);
      }
      if(search.get('footer') && search.get('footer') === "hide"){
        setShowFooter(false);
      }
      else{
        setShowFooter(true);
      }
    }
  });
  return (
    <>
      { showHeader && (<Navbar language={languages[language]}/>) }
      <Hero heading={_T("Seeker Privacy Policy")} language={languages[language]} showHeader={showHeader} showFooter={showFooter}/>
      <section id="seekerPolicy">
        <div className='pt-5 pb-5'>
          <div className='wrapper policy-container-wrapper'>
            <div className="policy-siderbar">
              <ol className="policy-siderbar-container"></ol>
            </div>
            <div className='policy-container' id='policyText'>
              <h2 className="secondary-heading">{_T("Introduction")}</h2>

              <p className="text-paragraph mb-3">{_T("Tawkeel application represented Tawkeel Commercial Services Company in Kingdom of Saudi Arabia always seeks to maintain the privacy of your personal data, and we are complying with the provisions of the Data Protection and the right to Privacy Laws regarding the protection of your personal data, and we have formulated this “Privacy Policy” to show you the nature of the data collected about you, and the purposes for which this data is used, so please read this policy carefully.")}</p>

              <p className="text-paragraph mb-5">{_T("The term data refers to all the data relating to the users of Tawkeel application, which includes their name, address, contact details and any other data that identify the user as well as other data related to the user's activities through the application and data related to the devices used in the process of accessing the application.")}</p>

              <h4 className="secondary-heading-alt">{_T("1. Personal data we collect")}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T("We collect the data provided by the client to register his account on the application which includes (the first name, the last name, the email, the country choice and mobile number).")}</li>
                <li>{_T("Data that you provide by filling in forms on the application. This includes information provided at the time of registration or at the time of posting material or requesting further services.")}</li>
                <li>{_T("The data added by the user to his personal file and the content published by him via the application.")}</li>
                <li>{_T("The client's data, the service description, and the client requirements that he provides the performer with before taking the step of intention and starting to implement the service.")}</li>
                <li>{_T("Electronic payment data or bank transfer data that the client provides us with for purposes of paying for the service (the cost of the Umrah service), which is reserved in the application until the performer finishes performing the Umrah.")}</li>
                <li>{_T("The data of ordering the service, which includes (the application fee, the name of the performer, the client, the date of the Umrah, the cost of performing the Umrah, the date the order is opened, the date the order is completed).")}</li>
                <li>{_T("The data you provide us with when you report a problem in the application.")}</li>
                <li>{_T("The data you provide us with when you communicate with us or the customer service for any reason.")}</li>
                <li>{_T("The data you provide us with when you evaluate the services you received via the application.")}</li>
                <li>{_T("The user shall grant us a non-exclusive, unlimited and irrevocable license to use his personal information submitted by him in the registration form online for the purposes of providing services, updating them or notifying him of matters connected with providing the services of the application.")}</li>
              </ul>

              <h4 className="secondary-heading-alt">{_T("2. Content")}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T("The client provides us with the content of messages, orders and comments through the application at his personal responsibility.")}</li>
                <li>{_T("The client provides us with the content of the messages he sends to the application to inquire about the content of the services.")}</li>
                <li>{_T("We collect the data you provide to us to report on comments or problems that violate the terms and policies of Tawkeel application.")}</li>
              </ul>

              <h4 className="secondary-heading-alt">{_T("3. Data of Contact")}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T("The client provides us with his number and his contact details when he contacts us through the application, whether via the application, email or phone.")}</li>
                <li>{_T("We allow the client to communicate with the application, in this case the customer provides us with the following information (name, email, subject of the message, content of the message).")}</li>
                <li>{_T("We collect the data you provide to us while communicating with us to inquire about any of our services or to encounter the problems that facing your use of the application.")}</li>
              </ul>


              <h4 className="secondary-heading-alt">{_T("4. Data that are collected automatically")}</h4>

              <p className="text-paragraph mb-5">{_T("Tawkeel application collects some data for the users, which does not identify the user or its contact data, and is automatically collected, which includes, for example (data of the devices used in contacts, time of contact, its duration, the location of contact, IP address, pages visited, advertisement categories that the user target).")}</p>

              <h4 className="secondary-heading-alt">{_T("5. Cookies Policy")}</h4>

              <p className="text-paragraph mb-5">{_T("TAWKEEL application adopts the cookies technology, which are small files stored within the hard disk of your computer. When you visit specific web pages from our application, the cookies identify your browser through a unique and random number without disclosing any personal information about you. The cookies help us to improve your experience of using the application and finding out which sections have the largest share of visit from our application.")}</p>

              <h4 className="secondary-heading-alt">{_T("6. Third Parties")}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T("The application provides links to other websites, and by clicking on them you will be redirected to these websites.")}</li>
                <li>{_T("Terms and conditions of third parties or merchants may be applied to the user, and these are not subject to our control and therefore the user must access to and agree on these policies before benefiting from the services or products provided by the third parties or merchants.")}</li>
              </ul>

              <h4 className="secondary-heading-alt">{_T("7. Purposes of Collecting Data")}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T("We collect data to register your account on the application, so that you can use our services and the various features we offer.")}</li>
                <li>{_T("We collect data to enable the client to communicate with the client through the application to agree on performing Umrah.")}</li>
                <li>{_T("We collect data to open and process orders through the application and to complete orders when the performer has completed the agreed service.")}</li>
                <li>{_T("We collect data to enable the client to pay for the service, follow the performer up, and ensure that the service is implemented before the application transfers the performer's dues.")}</li>
                <li>{_T("We collect data to decide the complaints submitted by the customer and this includes having access to the content of the provided service and all the correspondence done between the customer and the performer.")}</li>
                <li>{_T("We collect your data to enable you to add comments through the application.")}</li>
                <li>{_T("We collect your data for the purposes of documenting the contact details you have provided us with, and verifying your possession of them.")}</li>
                <li>{_T("We collect your data to enable you to assess the services you get through the application.")}</li>
                <li>{_T("We collect your data to enable you to contact us and provide replies to inquiries you have provided to us through the application.")}</li>
                <li>{_T("We collect your data to individualize the content and the advertisements for you through the application.")}</li>
                <li>{_T("We collect your data to nominate the advertisements and the products that we believe are of interest to you.")}</li>
                <li>{_T("We collect your data for statistical and internal work purposes on the application.")}</li>
                <li>{_T("We collect your data for law enforcement purposes or public authority decisions.")}</li>
              </ul>

              <h4 className="secondary-heading-alt">{_T("8. Limits of Data Disclosure")}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T("We disclose your data to our partners on the application, employees, those who work with us and clients for the purposes of implementing the services of Tawkeel application.")}</li>
                <li>{_T("We disclose your data to comply with the provisions of the law and, to execute the judicial decisions and the decisions of the competent authorities in the Kingdom.")}</li>
                <li>{_T("We disclose your data to implement our terms, conditions and any other agreement between you and the application.")}</li>
                <li>{_T("We may disclose your data to our co-providers as electronic payment providers for the purposes of verifying the validity of these transactions.")}</li>
              </ul>

              <h4 className="secondary-heading-alt">{_T("9. Storage of The Data")}</h4>

              <p className="text-paragraph mb-5">{_T("We application all your data on fully protected and secure servers to protect your data from any hacking or piracy, and the storage of your data may require its transferring outside the Kingdom of Saudi Arabia in case the application utilizes a hosting service provider. Therefore, you authorize us to deal with others regarding your data and authorize us to transfer and store it on our servers.")}</p>

              <h4 className="secondary-heading-alt">{_T("10. Integrity of The Data")}</h4>

              <p className="text-paragraph mb-5">{_T("You know that the Internet is not a 100% safe and secure mean, so our databases may be hacked into even though we have taken proper data protection procedures. From the perspective of our respect for your right to protect your data we will notify you within 3 days of the time we know about the hacking.")}</p>

              <h4 className="secondary-heading-alt">{_T("11. Non-disclosure Agreement")}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T("The parties to this agreement shall be bound to maintain the confidentiality and privacy of their personal data and they declare and shall be personally liable that the disclosure by any of them of the personal data to the other party is without any intervention by us and he shall be liable for that and we shall not incur any liability.")}</li>
                <li>{_T("The parties shall be bound not to disclose any personal data through the use of messages within the application and they shall be personally liable for any disclosure of this kind.")}</li>
                <li>{_T("You shall be bound to maintain the confidentiality of all the data connected with the details of the transactions via the application and not to use it in whichever form and you shall be legally liable and liable in contract for any illegal use or not permitted in relation to this data.")}</li>
                <li>{_T("The parties undertake to maintain the secrets of the application and each of them shall be liable to us for any breach of the confidentiality of the site and its processes.")}</li>
                <li>{_T("You shall be bound to abstain from disclosing any data connected with the operating of the application to third parties whether for free or not.")}</li>
                <li>{_T("You declare that you avoid the following of any means that helps the collection of data about the other users including e-mails, the mobile numbers and their other means of telecommunications.")}</li>
              </ul>

              <h4 className="secondary-heading-alt">{_T("12. App Permissions Policies")}</h4>

              <p className="text-paragraph mb-3">{_T("We are getting User locations to check for their Country. If they are from Saudia then we are hiding the Seeker account option from them. Then we are getting the location from the Performer side only when we are tracking the Umrah service. Not every time. This is a compulsory feature for our App because Seekers will not be able to see the location of Performer.")}</p>

              <ul className='text-unordered-list mb-5'>
                <li>{_T("Gallery: We need gallery permission to allow Performer to upload their ID card, or change the Profile Picture")}</li>
                <li>{_T("Camera: We need gallery permission to allow Performer to directly upload the picture of their haircut from their camera because it is necessary to make sure they have the hair cut for the rituals")}</li>
                <li>{_T("Microphone: We need access to a microphone to allow users to send voice messages")}</li>
                <li>{_T("Wake lock: We need Wake Lock permissions to allow user to not have the app locked while they are performing the ritual")}</li>
                <li>{_T("Internet: We need Internet Permission to allow the performer to upload their location data So that seekers can see what has tasks have they completed on their side when performing ritual")}</li>
              </ul>

              <h4 className="secondary-heading-alt">{_T("13. Request Data Deletion")}</h4>

              <p className="text-paragraph mb-3">{_T("If you wish to have your personal data removed from our application, please contact us at support@tawkeel.com. Please also make sure to mention your email address or mobile number which was used to create your account.")}</p>
              <p className="text-paragraph mb-3">{_T("We will make every reasonable effort to remove your personal data in a timely manner, subject to any legal or regulatory requirements that may apply. Please note that we may need to retain certain personal data for a reasonable period of time to comply with our legal or regulatory obligations, or for legitimate business purposes such as resolving disputes or enforcing our agreements.")}</p>
              <p className="text-paragraph mb-5">{_T("We will confirm receipt of your request within 10 days. We may require specific information from you to help us verify your identity and process your request. If we are unable to verify your identity, we may deny your request.")}</p>


              <h4 className="secondary-heading-alt">{_T('14. Location Permissions and Usage')}</h4>

              <p className="text-paragraph mb-3">{_T('The user shall be bound not to collect or exploit any data of any other parties who use the application, such as clients and service providers, and the use of data is limited to the purposes of providing services or benefiting from them only.')}</p>
              <p className="text-paragraph mb-3">{_T('Our app requires access to your device’s location in order to track your location during Umrah journeys. We collect both coarse and fine location data to provide accurate tracking and navigation services to help you perform Umrah rituals safely and efficiently.')}</p>
              <p className="text-paragraph mb-3">{_T('Why We Need Your Location')}</p>

              <ul className='text-unordered-list mb-3'>
                <li>{_T('Umrah Tracking: We use your location data to track your movements during Umrah journeys, including your current position, route, and points of interest. This information is essential for providing real-time navigation, guidance, and assistance to help you complete your Umrah rituals.')}</li>
              </ul>

              <p className="text-paragraph mb-3">{_T('How We Use Your Location')}</p>

              <ul className='text-unordered-list mb-3'>
                <li>{_T('Navigation: Your location data is used to provide turn-by-turn navigation, route planning, and guidance throughout your Umrah journey. We utilize mapping and GPS technologies to display your current location on maps and provide directions to various landmarks, holy sites, and facilities.')}</li>
                <li>{_T('Safety and Assistance: In case of emergencies or unforeseen circumstances, your location data enables us to provide immediate assistance, support, and guidance to ensure your safety and well-being during Umrah rituals.')}</li>
              </ul>

              <p className="text-paragraph mb-3">{_T('Sharing of Location Data')}</p>

              <ul className='text-unordered-list mb-3'>
                <li>{_T('Third-party Services: We do not share your location data with third-party service providers, such as mapping and navigation platforms. Although we only share the location with the seeker who ordered the umrah and wants to track the umrah performer.')}</li>
              </ul>

              <p className="text-paragraph mb-3">{_T('Your Privacy Rights')}</p>

              <ul className='text-unordered-list mb-5'>
                <li>{_T('Opt-out Options: You have the right to opt-out of location tracking at any time by adjusting the app’s settings or disabling location permissions on your device. However, please note that disabling location tracking may limit the functionality of certain features within the app.')}</li>
              </ul>

              <h4 className="secondary-heading-alt">{_T("15. Amendment of The Privacy Policy")}</h4>

              <p className="text-paragraph mb-5">{_T("We have the right to amend this privacy policy at any time, and we will publish the amendments within this document. Also, we will send a public notification via the application. And your continued use of the application after the amendments of this policy is considered as an express consent of you on these amendments, so please review this policy constantly.")}</p>

              <h4 className="secondary-heading-alt">{_T("16. Contact Us")}</h4>

              <p className="text-paragraph mb-3">{_T("You can contact us through:")}</p>

              <p className="text-paragraph mb-1">{_T("Email: support@tawkeel.com")}</p>

              <p className="text-paragraph mb-1">{_T("Call us on the number")} <span className='d-inline-block escape'>800 244 2203</span> ({_T("Toll Free")}) <span className='d-inline-block escape'>+966 800 244 2203</span> ({_T("International")})</p>
            </div>
          </div>

          {createList()}
        </div>
      </section>
      { showFooter && (<Footer language={languages[language]}/>) }
    </>
  );
}

export default Index;
