import React from 'react'
import {Link} from 'react-router-dom'
import Hero from "./Hero";
import Navbar from "../../components/NavbarInner";
import Footer from "../../components/FooterInner";
import {languages} from '../../common/languages'
import help_center_01 from '../../assets/images/help-center/performer-help-center-01.png'
import help_center_02 from '../../assets/images/help-center/performer-help-center-02.png'
import help_center_03 from '../../assets/images/help-center/4.png'
import help_center_04 from '../../assets/images/help-center/performer-help-center-03.png'
import help_center_05 from '../../assets/images/help-center/performer-help-center-04.png'
import help_center_06 from '../../assets/images/help-center/performer-help-center-05.png'
import help_center_07 from '../../assets/images/help-center/performer-help-center-06.png'
import image_07 from '../../assets/images/help-center/7.jpg'
import image_08 from '../../assets/images/help-center/8.png'
import { useEffect } from 'react';
import _T from '../../locale/HelpCenter/translator';
import { RP } from '../../components/FacebookPixel';
import '../../components/TikTokPixel.js';


function navigate_help_center(e,id,step){
    e.preventDefault()
    document.getElementsByClassName("helpCenter-container")[0].classList.remove("show-sidebar")
    let container = document.getElementsByClassName("helpCenter-content")[0]
    let loading = (100/document.getElementsByClassName("helpCenter-section").length)*step;
    document.getElementsByClassName("helpCenter-loading")[0].style.width = loading+"%"
    let offset = document.getElementById(id).offsetLeft 
    container.scrollTo({left: offset, behavior: 'smooth'});
}
function toggle_helpcenter_sidebar(){
    let e = document.getElementsByClassName("helpCenter-container")[0];
    if(e.classList.contains("show-sidebar")){
        document.getElementsByClassName("helpCenter-container")[0].classList.remove("show-sidebar")
    }
    else{
        document.getElementsByClassName("helpCenter-container")[0].classList.add("show-sidebar")
    }
}

document.addEventListener('click', function(event) {
    let sidebar = document.getElementsByClassName('helpCenter-sidebar')[0];
    if(sidebar){
        let toggle = document.getElementsByClassName('menuToggle')[0];
        let condition = sidebar.contains(event.target);
        let condition2 = toggle.contains(event.target);
        if (!condition && !condition2) {
            let e = document.getElementsByClassName("helpCenter-container")[0];
            if(e.classList.contains("show-sidebar")){
                document.getElementsByClassName("helpCenter-container")[0].classList.remove("show-sidebar")
            }
        }
    }
});
function PerformerHelpCenter({language}) {
    window.scrollTo({ top: 0});
    let direction = languages[language].direction
    if(direction === "RTL"){
      document.getElementsByTagName("body")[0].classList.add("RTL");
    }else{
      document.getElementsByTagName("body")[0].classList.remove("RTL");
    }
    useEffect(() => {
        const element = document.getElementById("helpCenter");
        let pos = element.offsetTop;
        window.scrollTo({ top: pos, behavior: 'smooth' });
    });

    useEffect(()=>{
      RP.pageView();
    },[])
    

    return (
        <>
            <Navbar language={languages[language]}/>
            <Hero heading={_T("Performer Help Center")} language={languages[language]}/>
            <section id='helpCenter'>
                <div className='wrapper helpCenter-wrapper'>
                    <div className='helpCenter-container'>
                        <div className='helpCenter-header'>
                            <div className='header-left'>
                                <button className='menuToggle' onClick={toggle_helpcenter_sidebar}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                <h4>{_T("Performer Help Center")}</h4>
                            </div>
                            <div className='header-right'>
                                <a href='https://tawkeelprod.blob.core.windows.net/webpdfs/[Performer]%20User%20Manual.pdf' className='btn btn-light has-icon escape' download>
                                    <svg id="generatePDF" xmlns="http://www.w3.org/2000/svg" width="20.072" height="23.417" viewBox="0 0 20.072 23.417">
                                        <path id="generatePDF01" data-name="Path 11785" d="M21.8,27v3.345H8.423V27H6.75v3.345a1.673,1.673,0,0,0,1.673,1.673H21.8a1.673,1.673,0,0,0,1.673-1.673V27Z" transform="translate(-5.077 -8.601)" fill="#3f9a97"/>
                                        <path id="generatePDF02" data-name="Path 11786" d="M20.738,21.6l-1.183-1.183-2.163,2.163V15.75H15.72v6.835l-2.163-2.163L12.375,21.6l4.182,4.182Z" transform="translate(-6.521 -5.714)" fill="#3f9a97"/>
                                        <path id="generatePDF03" data-name="Path 11787" d="M29.768,3.923V2.25H24.75v8.363h1.673V7.268h2.509V5.6H26.423V3.923Z" transform="translate(-9.696 -2.25)" fill="#3f9a97"/>
                                        <path id="generatePDF04" data-name="Path 11788" d="M17.97,10.613H14.625V2.25H17.97a2.511,2.511,0,0,1,2.509,2.509V8.1A2.511,2.511,0,0,1,17.97,10.613ZM16.3,8.941H17.97a.837.837,0,0,0,.836-.836V4.759a.837.837,0,0,0-.836-.836H16.3Z" transform="translate(-7.098 -2.25)" fill="#3f9a97"/>
                                        <path id="generatePDF05" data-name="Path 11789" d="M8.682,2.25H4.5v8.363H6.173V8.1H8.682a1.675,1.675,0,0,0,1.673-1.673V3.923A1.674,1.674,0,0,0,8.682,2.25ZM6.173,6.432V3.923H8.682V6.432Z" transform="translate(-4.5 -2.25)" fill="#3f9a97"/>
                                    </svg>
                                    {_T('Download PDF')} </a>
                            </div>
                        </div>
                        <div className='helpCenter-sidebar'>
                            <ul>
                                <li>
                                    <a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide01",1)} }>{_T('Register as Umrah Performer')}</a>
                                </li>
                                <li>
                                    <a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide02",2)} }>{_T('How to register as a performer')}</a>
                                    <ol>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide02",2)} }>{_T('Through Mobile Number')}</a></li>    
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide02",2)} }>{_T('Through social media platforms')}</a></li>
                                    </ol>
                                </li>
                                <li>
                                    <a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide03",3)} }>{_T('Verification')}</a>
                                    <ol>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide03",3)} }>{_T('Eligibility Quiz')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide03",3)} }>{_T('Authorization')}</a></li>
                                    </ol>
                                </li>
                                <li>
                                    <a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide04",4)} }>{_T('Main Screen')}</a>
                                    <ol>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide04",4)} }>{_T('Home')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide04",4)} }>{_T('Orders')}</a></li>
                                    </ol>
                                </li>
                                <li>
                                    <a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide05",5)} }>{_T('Performing the rituals of Umrah')}</a>
                                    <ol>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide05",5)} }>{_T('How to approve the application')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide05",5)} }>{_T('Confirmation')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide05",5)} }>{_T('Performing Umrah')}</a></li>
                                    </ol>
                                </li>
                                <li>
                                    <a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide06",6)} }>{_T('Steps to Perform Umrah')}</a>
                                    <ol>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide06",6)} }>{_T('Intention')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide06",6)} }>{_T('Reaching Grand Mosque')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide06",6)} }>{_T('Performing Tawaf')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide07",7)} }>{_T('Prayer behind Maqam Ibrahim')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide07",7)} }>{_T('Perform Saey')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide07",7)} }>{_T('Shave or Cut Hair')}</a></li>
                                    </ol>
                                </li>
                                <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide08",8)} }>{_T('Chat')}</a></li>
                                <li>
                                    <a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide09",9)} }>{_T('Settings')}</a>
                                    <ol>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide09",9)} }>{_T('User Details')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide09",9)} }>{_T('Application Language')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide09",9)} }>{_T('Speaking language')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide09",9)} }>{_T('Location Service and Sharing')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide09",9)} }>{_T('Notifications')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide09",9)} }>{_T('About Application')}</a></li>
                                    </ol>
                                </li>
                            </ul>
                        </div>
                        <div className='helpCenter-content'>
                            <div className='helpCenter-section' id="slide01">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h2 className='section-primary-heading'>{_T('Register as Umrah Performer')}</h2>
                                        <p className='section-paragraph'>{_T('The Umrah performer can register as a performer of Umrah through the application using the given link below, after being selected by the seeker:')}<br/><a href='https://tawkeel.onelink.me/u5qw/8ajifnsr' target="_blank">https://tawkeel.onelink.me/u5qw/8ajifnsr</a></p>
                                        <h3 className='section-secondary-heading'>{_T('Tawkeel Application')}</h3>
                                        <p className='section-paragraph'>{_T('It is an application based on providing pilgrims to perform the rituals of Umrah, on behalf of the incapable and deceased Muslims, which is permitted by the true Islamic religion.')}</p>
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={help_center_01} style={{maxWidth: "380px"}} alt="Tawkeel Application Screenshot"/>
                                    </div>
                                </div>
                            </div>
                            <div className='helpCenter-section' id="slide02">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h2 className='section-primary-heading'>{_T('How to register as a performer')}</h2>
                                        <p className='section-paragraph'>{_T('You can register as a performer of Umrah through a mobile phone number, or through one of the social platforms in the application. In order to be able to register as a performer of Umrah, please go through the following steps')}</p>
                                        <h3 className='section-secondary-heading'>{_T('Through Mobile Number')}</h3>
                                        <ol>
                                            <li>{_T('Please select the country code according to your country of residence')}</li>
                                            <li>{_T('When you finish entering the mobile phone number, please set your 6-digit OTP')}</li>
                                            <li>{_T('Proceed to creating the profile to fill in the required data, such as: the referral code, which is mandatory to register as a performer. As this reference code can be obtained through the authorized agent (administrator) by us, and in case the performer does not have the referral code, he can contact customer service via')}</li>
                                        </ol>
                                        <p className='section-paragraph mb-0'>{_T('Call the international customer service number')}</p>
                                        <p className='section-paragraph'><a href='tel:8002442203' className='d-inline-block escape'>800 244 2203</a></p>
                                        <p className='section-paragraph mb-0'>{_T('Or through live chat in the app or email to customer service')}</p>
                                        <p className='section-paragraph'><a href='mailto:support@tawkeel.com'>support@tawkeel.com</a></p>
                                        <h3 className='section-secondary-heading'>{_T('Through social media platforms')}</h3>
                                        <ol>
                                            <li>{_T('Please choose one of these platforms in the application (Facebook, Twitter, Google Gmail, Apple) the platform through which you want to register')}</li>
                                            <li>{_T('Move to creating the profile to fill in the required data such as the referral code, which is mandatory to register as a mod. This is a reference code that you can obtain through the agent authorized by us, and in the event that the performer does not have the status code, he can contact customer service')}</li>
                                        </ol>
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={help_center_02} style={{maxWidth: "380px"}} alt="Tawkeel Application Screenshot"/>
                                    </div>
                                </div>
                            </div>
                            <div className='helpCenter-section' id="slide03">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h3 className='section-primary-heading'>{_T('Verification')}</h3>
                                        <p className='section-paragraph'>{_T('To successfully register as a performance item, you must go through the following verification process:')}</p>
                                        <h3 className='section-secondary-heading'>{_T('Eligibility Quiz')}</h3>
                                        <p className='section-paragraph'>{_T('Answer a set of questions to check the eligibility of the performer')}</p>
                                        <h3 className='section-secondary-heading'>{_T('Authorization')}</h3>
                                        <p className='section-paragraph'>{_T('Enter the ID number and attach a copy of the ID card')}</p>
                                        <p className='section-paragraph'>{_T('Enter your bank account information (bank address, bank name, bank number).')}</p>
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={help_center_03} alt="Tawkeel Application Screenshot" />
                                    </div>
                                </div>
                            </div>
                            <div className='helpCenter-section' id="slide04">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h3 className='section-primary-heading'>{_T('Main Screen')}</h3>
                                        <h3 className='section-secondary-heading'>{_T('Home')}</h3>
                                        <p className='section-paragraph'>{_T('Through this page, you can know your performance in general')}</p>
                                        <ul>
                                            <li>{_T('Ratings by customers in terms of ratings')}</li>
                                            <li>{_T('Number of Umrah requests completed')}</li>
                                            <li>{_T('The number of Umrah applications rejected')}</li>
                                            <li>{_T('The number of canceled Umrah requests')}</li>
                                            <li>{_T('Your current balance')}</li>
                                        </ul>
                                        <h3 className='section-secondary-heading'>{_T('Orders')}</h3>
                                        <p className='section-paragraph'>{_T('Through this screen, you can view the status of your Umrah allowance requests, which are divided into two parts')}</p>
                                        <p className='section-paragraph'><strong>{_T('Current Orders:')}</strong> {_T('By clicking on the icon, you can see the details of the active Umrahs that have been accepted, are ready to be performed and are being worked on')}</p>
                                        <p className='section-paragraph'><strong>{_T('Previous Orders:')}</strong> {_T('Record all Umrah requests you received and their status in the system. Completed orders and canceled orders from previous orders can be viewed')}</p>
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={help_center_04} alt="Tawkeel Application Screenshot" />
                                    </div>
                                </div>
                            </div>
                            <div className='helpCenter-section' id="slide05">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h3 className='section-primary-heading'>{_T('Performing the rituals of Umrah')}</h3>
                                        <h3 className='section-secondary-heading'>{_T('1 - How to approve the application')}</h3>
                                        <p className='section-paragraph'>{_T('By clicking on the current requests icon as in the previous figure, the new requests page will appear and you can accept or reject the request')}</p>
                                        <h3 className='section-secondary-heading'>{_T('2 - Confirm the order')}</h3>
                                        <p className='section-paragraph'>{_T('When you accept the application, this means that you need to wait for the customer\'s confirmation to start performing the Umrah. It is also preferable, when accepting the request, to extract the Umrah permit from the Tawakkalna or Umrah application, and then to set an appointment through the Tawkeel application according to the date on the permit.')}</p>
                                        <h3 className='section-secondary-heading'>{_T('3 - Performing the Umrah')}</h3>
                                        <p className='section-paragraph'>{_T('Once the customer accepts the order, you can start by following these steps')}</p>
                                        <ul>
                                            <li>{_T('Download the picture wearing Ihram')}</li>
                                            <li>{_T('A pledge to perform Umrah')}</li>
                                            <li>{_T('Read the supplications according to the client\'s recommendation')}</li>
                                            <li>{_T('When performing the first step of the rituals of Umrah, the performer must sign the intention to perform Umrah on behalf of others')}</li>
                                        </ul>
                                        <p className='section-paragraph'>{_T('The method of performing Umrah on behalf of others through the application consists of 6 steps that can be traced from the Umrah tracking screen')}</p>
                                        <p className='section-paragraph'>{_T('The performer can correctly follow the steps of the Umrah rituals through the application')}</p>
                                        <p className='section-paragraph'>{_T('The performer of the Umrah can also get acquainted with the status of the request for Umrah from beginning to end with the possibility of communicating with the Umrah student during the performance of Umrah through the live chat in the application')}</p>
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={help_center_05} alt="Tawkeel Application Screenshot" />
                                    </div>
                                </div>
                            </div>
                            <div className='helpCenter-section' id="slide06">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h3 className='section-primary-heading'>{_T('Steps to perform Umrah')}</h3>
                                        <h3 className='section-secondary-heading'>{_T('1 - Intention')}</h3>
                                        <p className='section-paragraph'>{_T('In order for the application to function properly, the performer must be present at the meeqaat, establish the intention for Umrah, and is prohibited from it. The audio recording of the intention must be clearly recorded.')}</p>
                                        <h3 className='section-secondary-heading'>{_T('2 - Arrival at the Grand Mosque')}</h3>
                                        <p className='section-paragraph'>{_T('Upon reaching the Haram and making sure that the Umrah permit is valid, you must then press the “Start” button and then the “Finish” button to move to the next step.')}</p>
                                        <h3 className='section-secondary-heading'>{_T('3 - Performing Tawaf')}</h3>
                                        <p className='section-paragraph'>{_T('You should expose your right shoulder and lay. The two ends of the garment on the left shoulder of the Ihram garment')}</p>
                                        <p className='section-paragraph'>{_T('The application will ask you to allow access to your current location')}</p>
                                        <p className='section-paragraph'>{_T('You are in the tawaf. When you reach the starting point of the tawaf against the Black Stone, the tawaf runs start automatically which then leads to the midpoint and then the ending point to complete the circuit. You can see this counter and it is updated upon completion of each run. In this way, when the seventh round is completed, the tawaf is completed')}</p>
                                        <p className='section-paragraph'>{_T('There is also a supplication for each round of tawaf for the performer to recite')}</p>
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={help_center_06} alt="Tawkeel Application Screenshot" />
                                    </div>
                                </div>
                            </div>
                            <div className='helpCenter-section' id="slide07">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h3 className='section-primary-heading'>{_T('Steps to perform Umrah')}</h3>
                                        <h3 className='section-secondary-heading'>{_T('4 - Praying behind the shrine of Ibrahim')}</h3>
                                        <p className='section-paragraph'>{_T('After completing the tawaf, you must pray two rak\'ahs behind the shrine of Ibrahim, peace be upon him.')}</p>
                                        <h3 className='section-secondary-heading'>{_T('5 - Performing Saey')}</h3>
                                        <p className='section-paragraph'>{_T('The performer begins with Safa and ends with Marwa, and this is considered one round')}</p>
                                        <p className='section-paragraph'>{_T('You must confirm by clicking on the start button when you reach Safa, and then pressing again on the performance counter button to move to the next round')}</p>
                                        <p className='section-paragraph'>{_T('In the application there are also supplications for each half sought to be read by the performer')}</p>
                                        <h3 className='section-secondary-heading'>{_T('6 - Shaving or cutting hair')}</h3>
                                        <p className='section-paragraph'>{_T('After completing the Tawaf and Sa`y, you must shave or cut your hair and be sure to click on the camera icon to take a picture indicating a haircut or shortening')}</p>
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={help_center_07}  alt="Tawkeel Application Screenshot"/>
                                    </div>
                                </div>
                            </div>
                            <div className='helpCenter-section' id="slide08">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h3 className='section-primary-heading'>{_T('Chat')}</h3>
                                        <p className='section-paragraph'>{_T('Through this screen, you can communicate with the customer to find out the types of dhikr required')}</p>
                                        <p className='section-paragraph'>{_T('Or send photos of the rituals of Umrah or anything else related to Umrah.')}</p>
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={image_07}  alt="Tawkeel Application Screenshot"/>
                                    </div>
                                </div>
                            </div>
                            <div className='helpCenter-section' id="slide09">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h3 className='section-primary-heading'>{_T('Settings')}</h3>
                                        <p className='section-paragraph'>{_T('Through this screen, you can enter, modify and update the following data')}</p>
                                        <h3 className='section-secondary-heading'>{_T('1 - User Details')}</h3>
                                        <p className='section-paragraph'>{_T('Tawkeel allows you to keep your profile information such as your full name, email address, mobile phone number, profile picture and gender')}</p>
                                        <h3 className='section-secondary-heading'>{_T('2 - Application Language')}</h3>
                                        <p className='section-paragraph'>{_T('The application contains a wide range of languages, more than six languages ​​are available in the application.')}</p>
                                        <h3 className='section-secondary-heading'>{_T('3 - Speaking Language')}</h3>
                                        <p className='section-paragraph'>{_T('You can modify and update the language of communication with the Umrah seeker.')}</p>
                                        <p className='section-paragraph'>{_T('The performer can add 4 languages ​​as these languages ​​are spoken by the performer fluently to communicate properly with the Umrah student from all over the Islamic world.')}</p>
                                        <h3 className='section-secondary-heading'>{_T('4 - Location Service and Sharing')}</h3>
                                        <p className='section-paragraph'>{_T('Activate the ability to allow access to the current location through satellites.')}</p>
                                        <h3 className='section-secondary-heading'>{_T('5 - Notifications')}</h3>
                                        <p className='section-paragraph'>{_T('You can activate or disable the option to receive notifications, the performer can receive notifications such as (new request, end date of Umrah, new features in the system, etc.).')}</p>
                                        <h3 className='section-secondary-heading'>{_T('6 - About the application')}</h3>
                                        <p className='section-paragraph'>{_T('You can get additional information about the app from the about section like About Tawkeel, User Manual, Technical Support, Terms and Conditions, FAQ, About Applicaion, etc.')}</p>
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={image_08}  style={{maxWidth: "380px"}} alt="Tawkeel Application Screenshot"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='helpCenter-loading' style={{width: "14.28%"}}></div>
                    </div>
                </div>
            </section>
            <Footer language={languages[language]}/>
        </>
    )
}

export default PerformerHelpCenter;