import React from 'react'
import {Link} from 'react-router-dom'
import mainLogo from "../../assets/images/main-logo.png"
import _T from '../../locale/TermsAndConditions/translator'
function Hero({heading,language,showHeader,showFooter}){
    return (
        <section id='termsConditionsHome'>
            <div className='wrapper termsConditionsHome-wrapper'>
                <div className='section-left'>
                    <img src={mainLogo} alt="Tawkeel" />
                    <h1 className='primary-heading'>{heading}</h1>
                    <p className='text-paragraph'>{ _T("Please read the terms and conditions for use as per your specified user role.")}</p>
                    <div className='btn-group hero-btn-group'>
                        <Link to={language.text === "English" ? `/en/terms-and-conditions/performer?header=${showHeader?"show":"hide"}&footer=${showFooter?"show":"hide"}` : `/terms-and-conditions/performer?header=${showHeader?"show":"hide"}&footer=${showFooter?"show":"hide"}`} className='btn btn-primary-home mt-1'>{ _T("For Performer")}</Link>
                        <Link to={language.text === "English" ? `/en/terms-and-conditions/seeker?header=${showHeader?"show":"hide"}&footer=${showFooter?"show":"hide"}` : `/terms-and-conditions/seeker?header=${showHeader?"show":"hide"}&footer=${showFooter?"show":"hide"}`} className='btn btn-primary-home mt-1'>{ _T("For Seeker")}</Link>
                    </div>
                </div>
                <div className='section-right'>
                    {/* <img src={helpCenterHero} /> */}
                </div>
            </div>
        </section>
    )
}

export default Hero;