import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Navbar from "../../components/NavbarInner";
import Footer from "../../components/FooterInner";
import Hero from "./Hero";
import {languages} from '../../common/languages'
import "../../assets/css/privacy-policy.css";
import _T from "../../locale/PrivacyPolicy/translator";
import { RP } from "../../components/FacebookPixel";
import { useEffect } from "react";
import '../../components/TikTokPixel.js';
function Index({language}) {
  const [search, setSearch] = useSearchParams();
  const [showHeader, setShowHeader] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  useEffect(()=>{
    if(search){
      if(search.get('header') && search.get('header') === "hide"){
        setShowHeader(false);
      }
      else{
        setShowHeader(true);
      }
      if(search.get('footer') && search.get('footer') === "hide"){
        setShowFooter(false);
      }
      else{
        setShowFooter(true);
      }
    }
    RP.pageView();
    window.scrollTo({ top: 0});
    let direction = languages[language].direction
    if(direction === "RTL"){
      document.getElementsByTagName("body")[0].classList.add("RTL");
    }else{
      document.getElementsByTagName("body")[0].classList.remove("RTL");
    }
  },[])

  return (
    <>
        { showHeader && (<Navbar language={languages[language]}/>) }
        <Hero heading={_T("Privacy Policy")} language={languages[language]} showHeader={showHeader} showFooter={showFooter}/>
        { showFooter && (<Footer language={languages[language]}/>) }
    </>
  );
}

export default Index;
