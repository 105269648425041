function _TN(text) {
    const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
    const translations = {
        "Tawkeel Initiative for Umrah Al Badal": {
            "en": "Tawkeel Initiative for Umrah Al Badal",
            "ar": "مبادرة توكيل لعمرة البدل",
        },
        "For the beneficiaries of the Fund for Martyrs, Wounded, Pow, and Missing in Action": {
            "en": "For the beneficiaries of the Fund for Martyrs, Wounded, Pow, and Missing in Action",
            "ar": "لمستفيدي صندوق الشهداء والمصابين والأسرى والمفقودين",
        },
        "View Packages": {
            "en": "View Packages",
            "ar": "عرض الباقات",
        },
        "Name": {
            "en": "Name",
            "ar": "الاسم",
        },
        "Mobile Number": {
            "en": "Mobile Number",
            "ar": "رقم الجوال",
        },
        "City": {
            "en": "City",
            "ar": "المدينة",
        },
        "Choose a package": {
            "en": "Choose a package",
            "ar": "اختار الباقة",
        },
        "Packages": {
            "en": "Packages",
            "ar": "الباقات",
        },
        "Umrah through the application": {
            "en": "Umrah through the application",
            "ar": "عمرات بدل عبر تطبيق توكيل",
        },
        "Umrah package gift for the families of the martyrs": {
            "en": "Umrah package gift for the families of the martyrs",
            "ar": "هدية باقة عمرة لذوي الشهداء",
        },
        "Price": {
            "en": "Price",
            "ar": "السعر",
        },
        "Choose": {
            "en": "Choose",
            "ar": "حدد الباقة",
        },
        "First": {
            "en": "First",
            "ar": "الاولى",
        },
        "Second": {
            "en": "Second",
            "ar": "الثانية",
        },
        "Third": {
            "en": "Third",
            "ar": "الثالثة",
        },
        "Forth": {
            "en": "Forth",
            "ar": "الرابعة",
        },
        "Fifth": {
            "en": "Fifth",
            "ar": "الخامسة",
        },
        "Sixth": {
            "en": "Sixth",
            "ar": "السادسة",
        },
        "Seventh": {
            "en": "Seventh",
            "ar": "السابعة",
        },
        "Umrah Al-Badal Request": {
            "en": "Umrah Al-Badal Request",
            "ar": "طلب عمرة بدل",
        },
        "Person": {
            "en": "Person",
            "ar": "شخص",
        },
        "SAR": {
            "en": "SAR",
            "ar": "ريال",
        },
        "Transfer": {
            "en": "Transfer",
            "ar": "للتحويل",
        },
        "Riyadh Bank": {
            "en": "Riyadh Bank",
            "ar": "بنك الرياض",
        },
        "Account No": {
            "en": "Account No",
            "ar": "رقم الحساب",
        },
        "Unified Account Number (IBAN):": {
            "en": "Unified Account Number (IBAN):",
            "ar": ":رقــــــــــــــــم الحســــــــــــاب الموحــــــــد (الآيبــــــــــــــــــــان)",
        },
        "Current Account No:": {
            "en": "Current Account No:",
            "ar": ":رقم الحســــــاب الجــــــاري",
        },
        "TAWKEEL COMPANY FOR COMMERCIAL SERVICES": {
            "en": "TAWKEEL COMPANY FOR COMMERCIAL SERVICES",
            "ar": "شركة توكيل للخدمات التجارية",
        },
        "Send": {
            "en": "Send",
            "ar": "إرسال",
        },
        "Next": {
            "en": "Next",
            "ar": "التالي",
        },
        "Please provide a valid name": {
            "en": "Please provide a valid name",
            "ar": "أدخل اسمًا صالحًا",
        },
        "Please provide a valid mobile number": {
            "en": "Please provide a valid mobile number",
            "ar": "أدخل رقم جوال صالحًا",
        },
        "Please provide a valid city": {
            "en": "Please provide a valid city",
            "ar": "أدخل مدينة صالحة",
        },
        "Please select at least one package": {
            "en": "Please select at least one package",
            "ar": "اختر باقة واحدة على الأقل",
        },
        "Something went wrong. Please try again!": {
            "en": "Something went wrong. Please try again!",
            "ar": "حدث خطأ ما, يرجى المحاولة مرة أخرى!",
        },
        "Your request has been submitted. We will contact you shortly.": {
            "en": "Your request has been submitted. We will contact you shortly.",
            "ar": "تم استلام طلبك، سنتواصل معك قريبا",
        },
        "Category": {
            "en": "Category",
            "ar": "الفئة",
        },
        "Our Soldiers": {
            "en": "Our Soldiers",
            "ar": "جنودنا",
        },
        "Our Heroes": {
            "en": "Our Heroes",
            "ar": "أبطالنا",
        },
        "The Brave": {
            "en": "The Brave",
            "ar": "البواسل",
        },
        "Umrah": {
            "en": "Umrah",
            "ar": "عمرة",
        }
    }
    return translations[text] ? translations[text][language] : text;
}
export default _TN;