function _TN(text) {
    const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
    const translations = {
        "View News": {
            "en": "View News",
            "ar": "عرض الأخبار",
            "ur": ""
        },
        "The following section showcases our latest news updates, providing insights into recent developments and noteworthy events.": {
            "en": "The following section showcases our latest news updates, providing insights into recent developments and noteworthy events.",
            "ar": "القسم التالي يُعرض أحدث تحديثات الأخبار لدينا، مقدمًا نظرة على التطورات الأخيرة والأحداث الملحوظة.",
            "ur": ""
        },
        "Tawkeel": {
            "en": "Tawkeel",
            "ar": "توكيل",
            "ur": ""
        },
        "Our News": {
            "en": "Our News",
            "ar": "أخبارنا",
            "ur": ""
        },
        "Read More": {
            "en": "Read More",
            "ar": "قراءة المزيد",
            "ur": ""
        },
        "View More News": {
            "en": "View More News",
            "ar": "عرض المزيد من الأخبار",
            "ur": ""
        },
        "MATA and Tawkeel collaborate to avoid Umrah Al Badal fraud": {
            "en": "MATA and Tawkeel collaborate to avoid Umrah Al Badal fraud",
            "ar": "ماتا وتوكيل يتعاونان لتجنب الاحتيال في عمرة البدل.",
            "ur": ""
        },
        "KUALA LUMPUR - The Malaysian Association of Travel Agencies (MATA) has partnered with Tawkeel, a leading Saudi technology company, to leverage innovative technology solutions to prevent fraud by Umrah Al Badal operators.": {
            "en": "KUALA LUMPUR - The Malaysian Association of Travel Agencies (MATA) has partnered with Tawkeel, a leading Saudi technology company, to leverage innovative technology solutions to prevent fraud by Umrah Al Badal operators.",
            "ar": "كوالالمبور - اتحاد وكالات السفر الماليزي (MATA) قد شرك مع توكيل، شركة تكنولوجيا سعودية رائدة، للاستفادة من حلول التكنولوجيا الابتكارية لمنع الاحتيال من قبل مشغلي عمرة البدل.",
            "ur": ""
        },
        "Tawkeel introduces its services at the Hajj and Umrah Expo 2023": {
            "en": "Tawkeel introduces its services at the Hajj and Umrah Expo 2023",
            "ar": "توكيل يقدم خدماته في معرض الحج والعمرة لعام 2023.",
            "ur": ""
        },
        "The Tawkeel application for Hajj and Umrah showcased its services provided to facilitate the performance of rituals for those unable to do so and targets Muslims around the world.": {
            "en": "The Tawkeel application for Hajj and Umrah showcased its services provided to facilitate the performance of rituals for those unable to do so and targets Muslims around the world.",
            "ar": "تطبيق توكيل للحج والعمرة عرض خدماته المقدمة لتسهيل أداء الشعائر لأولئك الذين لا يستطيعون القيام بها، ويستهدف المسلمين في جميع أنحاء العالم.",
            "ur": ""
        },
        "Hajj 2023: How technology is transforming pilgrimage": {
            "en": "Hajj 2023: How technology is transforming pilgrimage",
            "ar": "الحج لعام 2023: كيف تُحدث التكنولوجيا تحولًا في الحج",
            "ur": ""
        },
        "A Saudi start-up is showing off its Tawkeel application that helps Muslims around the world to delegate rituals to be performed by others on their behalf.": {
            "en": "A Saudi start-up is showing off its Tawkeel application that helps Muslims around the world to delegate rituals to be performed by others on their behalf.",
            "ar": "شركة ناشئة سعودية تعرض تطبيقها توكيل الذي يساعد المسلمين في جميع أنحاء العالم على تفويض الشعائر ليقوم بها آخرون نيابةً عنهم.",
            "ur": ""
        },
        "As a testament to its commitment to delivering top-notch services, Tawkeel is preparing to showcase its latest offerings and innovative solutions designed to enhance the Umrah on behalf of others service": {
            "en": "As a testament to its commitment to delivering top-notch services, Tawkeel is preparing to showcase its latest offerings and innovative solutions designed to enhance the Umrah on behalf of others service",
            "ar": "في خطوة تعكس التزامها بتقديم أفضل الخدمات تستعد للمشاركة وعرض أحدث خدماتها وحلولها المبتكرة لتسهيل خدمة عمرة البدل",
            "ur": ""
        },
        "Tawkeel Company is getting ready to Participate in the Hajj Conference and Exhibition 2025": {
            "en": "Tawkeel Company is getting ready to Participate in the Hajj Conference and Exhibition 2025",
            "ar": "شركة توكيل تستعد للمشاركة في مؤتمر ومعرض الحج 2025",
            "ur": ""
        },
        "Hajj Conference and Exhibition 2025": {
            "en": "Hajj Conference and Exhibition 2025",
            "ar": "مؤتمر ومعرض الحج 2025",
            "ur": ""
        },
        "The Fund for Martyrs, Wounded, POW, and Missing in Action signed an agreement with Tawkeel": {
            "en": "The Fund for Martyrs, Wounded, POW, and Missing in Action signed an agreement with Tawkeel",
            "ar": "توقيع اتفاقية بين شركة توكيل و صندوق الشهداء والمصابين والأسرى والمفقودين",
            "ur": ""
        },
        "The agreement was signed by His Royal Highness Prince Khalid bin Saud Al Faisal, Chairman of the Tawkeel Company, and Mr. Talal bin Othman Al-Muammar, Secretary-General of the Fund.": {
            "en": "The agreement was signed by His Royal Highness Prince Khalid bin Saud Al Faisal, Chairman of the Tawkeel Company, and Mr. Talal bin Othman Al-Muammar, Secretary-General of the Fund.",
            "ar": "تمت برئاسة صاحب السمو الملكي الأمير خالد بن سعود الفيصل، رئيس مجلس إدارة شركة توكيل، وبحضور سعادة الأستاذ طلال بن عثمان المعمر، الأمين العام للصندوق.",
            "ur": ""
        },
        "sample": {
            "en": "sample",
            "ar": "sample",
            "ur": ""
        },
        "sample": {
            "en": "sample",
            "ar": "sample",
            "ur": ""
        },
        "sample": {
            "en": "sample",
            "ar": "sample",
            "ur": ""
        },
    }
    return translations[text] ? translations[text][language] : text;
}
export default _TN;