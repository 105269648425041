import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import Navbar from "../../components/NavbarInner";
import Footer from "../../components/FooterInner";
import Hero from "./Hero";
import { languages } from '../../common/languages'
import "../../assets/css/terms-and-conditions.css";
import _T from '../../locale/TermsAndConditions/translator';
import { RP } from '../../components/FacebookPixel';
import '../../components/TikTokPixel.js';


function createList() {
  let points = document.getElementsByClassName("secondary-heading-alt");
  let sidebar = document.getElementsByClassName("terms-conditions-siderbar-container")[0];
  if (sidebar) {
    sidebar.innerHTML = ""
  }
  for (let i = 0; i < points.length; i++) {
    let heading = points[i];
    heading.setAttribute("id", "section_pointer_" + i);
    const node = document.createElement("li");
    const anchor = document.createElement("a");
    node.setAttribute("data-number", i + 1);
    anchor.setAttribute("href", "#");
    anchor.setAttribute("data-pointer", "section_pointer_" + i);
    anchor.addEventListener("click", sidebar_navigation);
    const textnode = document.createTextNode(heading.textContent.replace(/\d+\.|^\s+|\s$/g, ''));
    anchor.appendChild(textnode);
    node.appendChild(anchor);
    sidebar.appendChild(node);
  }
}

function sidebar_navigation(e) {
  e.preventDefault()
  let id = e.target.attributes["data-pointer"].nodeValue;
  let offset = document.getElementById(id).offsetTop - 50;
  window.scrollTo({ top: offset, behavior: 'smooth' });
}

function Index({ language }) {
  const [search, setSearch] = useSearchParams();
  const [showHeader, setShowHeader] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    createList();
    const element = document.getElementById("performerTerms");
    let pos = element.offsetTop;
    window.scrollTo({ top: pos, behavior: 'smooth' });

    RP.pageView();
    if(search){
      if(search.get('header') && search.get('header') === "hide"){
        setShowHeader(false);
      }
      else{
        setShowHeader(true);
      }
      if(search.get('footer') && search.get('footer') === "hide"){
        setShowFooter(false);
      }
      else{
        setShowFooter(true);
      }
    }
  }, [])

  return (
    <>
      { showHeader && (<Navbar language={languages[language]}/>) }
      <Hero heading={_T("Performer Terms and Conditions")} language={languages[language]} showHeader={showHeader} showFooter={showFooter}/>
      <section id="performerTerms">
        <div className='pt-5 pb-5'>
          <div className='wrapper terms-container-wrapper'>
            <div className="terms-conditions-siderbar">
              <ol className="terms-conditions-siderbar-container"></ol>
            </div>
            <div className='terms-container' >
              <h2 className="secondary-heading">{_T("Introduction")}</h2>

              <p className="text-paragraph mb-3">{_T('We are proud of your visit to Tawkeel application.')}</p>

              <p className="text-paragraph mb-5">{_T('We provide the services of Tawkeel application as an online platform intermediate between the stores on one hand and the client on the other hand in the Kingdom of Saudi Arabia in accordance with Saudi laws, one of the projects and activities of the Tawkeel Commercial Services Company, based in the Kingdom of Saudi Arabia. We provide these terms and conditions to govern and regulate the legal relationships between Tawkeel and its users, so please read these terms in an appropriate attention before using the application.')}</p>

              <h4 className="secondary-heading-alt">1. {_T('Definitions')}</h4>

              <p className="text-paragraph mb-3">{_T('In applying the provisions of this agreement, wherever contained in it, the following words and phrases have the meaning outlined associated to each of them:')}</p>

              <p className="text-paragraph mb-3">{_T('"Agreement" refers to the contract that connects the application to its users, which includes the Terms and Conditions Document and the Privacy Policy Document.')}</p>

              <p className="text-paragraph mb-3">{_T('"Tawkeel", "application", "application", "website", "we" or "our" refers to TAWKEEL application and the website www.tawkeel.com as well as Tawkeel Commercial Services Company in the Kingdom of Saudi Arabia, Jeddah, Address: Al-Salamah neighborhood - Quraish Street, Commercial Register No. 4030413635, Date: 30.8.1442, Phone No. 800 244 2203 (Toll free) +966 800 244 2203 (International), E-mail: support@tawkeel.com.')}</p>

              <p className="text-paragraph mb-3">{_T('"Seeker" refers to every Muslim person who wishes to authorize someone else to perform the rituals of Umrah in the Kingdom of Saudi Arabia in accordance with the provisions of the Sunnah.')}</p>

              <p className="text-paragraph mb-3">{_T('"Performer of the Umrah Al badal", "Performer", "User", "You" or "Your" refers to refers to every Muslim person who uses "Tawkeel" application for the purpose of performing the Umrah Al Badal on behalf of the persons wishing to do so "seekers" with the obligation to perform the Umrah in accordance with the rituals and stuff stated in Sunnah, along with the obligation to the provisions of this agreement and regulations in force in the Kingdom of Saudi Arabia.')}</p>

              <p className="text-paragraph mb-3">{_T('"Third parties" refers to all persons and bodies that we do not have control over and are not under our supervision.')}</p>

              <p className="text-paragraph mb-5">{_T('"Laws" refer to the laws applicable in the Kingdom of Saudi Arabia.')}</p>

              <h4 className="secondary-heading-alt">2. {_T('Descriptions of Services')}</h4>

              <p className="text-paragraph mb-5">{_T('The application serves as an electronic medium to perform the Umrah Al Badal, so we allow each person who is unable to perform the Umrah to contact through the application with persons wishing to perform the Umrah on behalf of others, while enabling the one who requests the service to track the steps of the performer from his point of departure to the performance of the full rituals, taking into account the stuff stated in Sunnah that related to the performance of Umrah.')}</p>

              <h4 className="secondary-heading-alt">3. {_T("Seeker's Conditions")}</h4>
              <ul className='text-unordered-list mb-3'>
                <li>{_T('Since the services we provide are all electronic, and we deal with all our users electronically, we have had to waive written contracts and documents that must be signed with each user and counting on the electronic documents and contracts within a systematic framework in which we take into account all applicable legal provisions.')}</li>
                <li>{_T('This agreement is an electronic contract between the application and all its users, replaces all oral or written agreements prior to accession to it, and this contract is legally valid as it meets all legal requirements of consent, subject-matter and cause, and offer and acceptance are available in this electronic contract which are required by the applicable laws for the validity of the contracts.')}</li>
                <li>{_T('This electronic contract produces all the legal effects entail by the written contracts signed between the parties, and this contract replaces them, takes their judgment and entails their effects.')}</li>
                <li>{_T('This electronic contract is subject to modification and updating continuously to comply with the services provided by the "Tawkeel" application, and the user is responsible for revising this agreement periodically to revise any modification to those terms and conditions, and any use or access to our services after the modifications we have made is an express consent to them and an acceptance of the new terms.')}</li>
                <li>{_T('You are not licensed to interpret or construe any of the conditions of this agreement, and "Tawkeel" platform is the only authority competent to interpret or construe the terms of this agreement, so you should contact us immediately in the case that none of these terms are clear to you.')}</li>
                <li>{_T('In electronic contracts, the consent to them don\'t require obtaining written signatures of the users, but the consent to them is done by electronic means, and in "Tawkeel" application there are two types of consent and accession to this agreement, namely express consent and tacit consent, which are ultimately considered an electronic signature on this agreement:')}</li>
              </ul>

              <p className="text-paragraph mb-3">{_T('Express Consent: Consent is express if the user registers his account on the Tawkeel application, provides services through it or requests to benefit from the services provided through it, and this consent is considered an electronic signature of this agreement.')}</p>
              <p className="text-paragraph mb-5">{_T('Tacit Consent: Consent is tacit if the user uses or visits the Tawkeel application services or stays on the application for long periods of time, and this type of use is considered an electronic signature of this agreement.')}</p>


              <h4 className="secondary-heading-alt">4. {_T('Terms of accession to the agreement')}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T('As this agreement is an electronic contract between the Tawkeel application and all its users, legal and legitimate capacity must be available in all the users of the application in accordance with applicable laws.')}</li>
                <li>{_T('The user must be not less than 18 years old at the time of accession to this agreement, and if the user is less than that, he/she must be accede to this agreement by the legal guardian in accordance with the provisions of guardianship applicable.')}</li>
                <li>{_T('The user must see the provisions of this agreement in full before accession to it, as the user\'s entry with us in this agreement means that he/she has seen all its terms, and is bound by it and by all the legal implications arising from the application of its provisions.')}</li>
                <li>{_T('Throughout the period of accession to this agreement, the user must ensure that all the terms and requirements mentioned in the agreement, as well as all the terms and requirements stipulated in the applicable laws, are available.')}</li>
                <li>{_T('If the user loses any of the terms of this agreement, this agreement will be null for that user, while applying all legal implications arising during the period of its validity to the user.')}</li>
              </ul>

              <h4 className="secondary-heading-alt">5. {_T('Legal Notices')}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T('All legal notices required to be communicated in accordance with applicable laws are sent through the means of communication provided to us by the user, which includes telephone call or telephone messages or e-mail, and these telephone or electronic notices replace all legally required written notices and are officially approved between the parties.')}</li>
                <li>{_T('Each user acknowledges that he/she has waived any legal rights to receive any written notices from the Tawkeel application, and acknowledges that the application shall bound not to provide written notices and are replaced by telephone or electronic notices in the production of their legal effects as pointed out above, and is invoked before all official authorities.')}</li>
              </ul>

              <h4 className="secondary-heading-alt">6. {_T('Conditions of Account')}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T('The performer can register his account on the application that allows him to have many different features and services. The performer must register on the application with a decent name, and undertakes not to register with any unknown, unreal or misleading names.')}</li>
                <li>{_T('To register the membership on the application, the performer must provide us with the following data (name, mobile number, e-mail, A bank account in the performer\'s name, a copy of the national ID/ the residence permit, the performer should be signed up on Tawakkalna” application).')}</li>
                <li>{_T('The performer must register for membership on Tawkeel application with his data and email, so that he can receive any notifications sent to him from Tawkeel application.')}</li>
                <li>{_T('Any performer is forbidden to register on our application with more than one account and we will delete all accounts if we found that out. The performer under 18 years old shall be prohibited from registering an account with us or in case we have broken down his account before due to the violation of our terms and conditions or the privacy policy.')}</li>
                <li>{_T('The performer shall maintain his username and password, and not disclose them to others, and in all cases the performer is committed to all the transactions made through his account on the application.')}</li>
                <li>{_T('The account on the application is provided with a licensing system for using the account, so we have the right to revoke this License and terminate the account at any time without providing any reasons.')}</li>
              </ul>

              <h4 className="secondary-heading-alt">7. {_T('Conditions of Use')}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T('These conditions were formulated in accordance with Saudi laws. Products are provided through the application in accordance with the laws applicable in the Kingdom of Saudi Arabia, therefore you comply with these laws regardless of the country to which you belong.')}</li>
                <li>{_T('The user must be legally qualified to enter into this Agreement with us and he must be 18 years and older at the time of using our application.')}</li>
                <li>{_T('Any information required must be entered on the application correctly and you must be responsible for it, its recency, quality, and legality, and you are obligated to update it whenever any change occurs.')}</li>
                <li>{_T('The application must be used within the purposes declared through these terms or those declared through the pages of the application, and that our services should be used in good faith and not to manipulate the application or its clients in any way.')}</li>
                <li>{_T('The application must be used legally and that this use should be serious and the users shouldn\'t use the application for the purposes of fraud, scam, illegal communication, fake communication or harming to any party and that exercising any actions that expose the application to the legal accountability must be forbidden.')}</li>
                <li>{_T('The user must notify us in case of finding out any illegal practices or activities through the application.')}</li>
                <li>{_T('The user must maintain the reputation of the application, not to offend the application directly or indirectly, does not cause us direct or indirect damages, and does not cause us any legal claims.')}</li>
                <li>{_T('It is forbidden to resell or commercially exploit any part of the application, or profiteering from this, except for activities permitted by us.')}</li>
                <li>{_T('Tawkeel application reserves the right to make any modifications or changes to its application and to policies and agreements associated with Tawkeel including terms and conditions.')}</li>
                <li>{_T('The application reserves all its legal rights in the case that any user of the application violates our legal, legitimate rights or our rights enshrined in this agreement.')}</li>
              </ul>

              <h4 className="secondary-heading-alt">8. {_T('Content Policy')}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T('The content is provided through Tawkeel application for information purposes only, and for the purposes of providing our services exclusively, and it should not be dedicated for any content, websites or bodies that are not ours.')}</li>
                <li>{_T('The application provides the content through it for the purposes of providing and displaying our services to the users and marketing our services through the application.')}</li>
                <li>{_T('Texts, drawings, photographs, logos, illustrations, explanations, data and other materials provided by us on or through the services of Tawkeel application in addition to its selection, compilation and arrangement that may contain errors, omissions, typographical errors or to be outdated, and the application may change, delete or update any content at any time without prior notice.')}</li>
                <li>{_T('The data available through the application may include some unintentional errors, so you exempt us from any liability arising from this. Also, you undertake to contact us to inquire and request to correct any errors in the data.')}</li>
                <li>{_T('The user must check any content available through the application, check its authenticity and accuracy and to conduct due diligence like the person who is keen to handle the content.')}</li>
                <li>{_T('Tawkeel application doesn\'t bear any legal liability arising from the content available through the application, and you explicitly exempt us from doing so.')}</li>
                <li>{_T('All the data available to other people through the application is provided at their own responsibility without any guarantees from the application.')}</li>
                <li>{_T('The content provided by the user through the application must be legal and does not include any assault on the rights of others.')}</li>
                <li>{_T('The user is legally responsible for any content, data, information, files, documents, papers or messages that he provides, sends or exchanges through the application.')}</li>
                <li>{_T('The user is responsible before all bodies, authorities, courts and investigative bodies for any sanctions or compensations related to any illegal content he provides through the application.')}</li>
              </ul>

              <h4 className="secondary-heading-alt">9. {_T('Terms of the Performer of the Umrah Al Badal')}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T('The performer must be a Saudi national or residing in the Kingdom officially, and the performer is obligated to provide us with the national ID card if he is a Saudi or his residence permit if he is residing in the Kingdom, and the residence permit must be valid.')}</li>
                <li>{_T('The performer should be signed up on (Tawakkalna) application.')}</li>
                <li>{_T('The performer must be vaccinated with 2 doses of coronavirus vaccines.')}</li>
                <li>{_T('The performer must perform the Umrah at the time agreed upon with the Seeker, should devote himself full-time to perform the Umrah, and not to be associated with any other business during this period.')}</li>
                <li>{_T('The performer must perform the Umrah in accordance with the Sunnah and should not commit any Sharia violations, and that the Umrah must be performed with the intent specified by the one who requests the service.')}</li>
                <li>{_T('The performer must be in constant contact with the application while performing the Umrah so that the one who requests the service can track the steps and perform the rituals.')}</li>
                <li>{_T('The performer is prohibited from manipulating the application or the one who requests the service, and that the service is provided in good faith and as agreed with the Seeker.')}</li>
                <li>{_T('The performer is obligated to obtain the necessary permits to perform the Umrah requested by the competent authorities in order to avoid that the performer would be fined, and in the event of a violation, the performer shall bear the full fine.')}</li>
                <li>{_T('In the event that the performer breaches any obligations stipulated in this agreement, the Sunnah, or any other Sharia provisions, the application and/or the Seeker has the right to cancel the service and not transfer the specified amount for performing the Umrah.')}</li>
                <li>{_T('In the event that the performer commits any statutory violations, he bears full legal responsibility without any responsibility on the application, and the performer releases our legal responsibility for any violations committed by him.')}</li>
              </ul>

              <h4 className="secondary-heading-alt">10. {_T('Payment Policy')}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T('The performer shall receive 150 riyals for each completed order, provided that the order meets all the requirements outlined in this agreement.')}</li>
                <li>{_T('The aforementioned amount of the Umrah is inclusive of all transportation, accommodation, food, clothing and all that the Umrah pilgrim needs to perform the Umrah.')}</li>
                <li>{_T('The performer must provide us with a bank account in his personal name stated in the national identity or valid residence. If the performer provides us with an account in another person\'s name, the application will not be able to transfer the amount to the performer.')}</li>
                <li>{_T('The amount will be transferred to the performer within a maximum period of 15 days from the date the order is properly completed.')}</li>
                <li>{_T('The performer shall bear any fees related to the performance of the service or the transfer of funds, and the application has the right to deduct these fees from the amount before transferring it to the performer.')}</li>
                <li>{_T('The application may set an administrative fee for the service within the application, and the application will inform the performer of this fee before it is deducted from the amount.')}</li>
                <li>{_T('The performer bears full legal responsibility for the transfer data he provides to us, and the application will not be responsible in any way if the transfer method is illegal or in the event that the performer is unable to receive his dues.')}</li>
                <li>{_T('The application does not bear any statutory responsibility or compensation if the amount is confiscated by the official bodies in the Kingdom for any reason.')}</li>
              </ul>

              <h4 className="secondary-heading-alt">11. {_T('Cancellation Policy')}</h4>

              <p className="text-paragraph mb-3">{_T('Once the seeker order through the application, he will not be able to cancel that order.')}</p>

              <p className="text-paragraph mb-3">{_T('The performer has the right to cancel before making the step of the Umrah intent, after that the performer will not have the right to cancel the Umrah.')}</p>

              <p className="text-paragraph mb-5">{_T('In the event that the performer cancels the Umrah in violation of the provisions of this agreement and without a legal justification, the application has the right to permanently block his account and the seeker has the right to claim for compensation.')}</p>

              <h4 className="secondary-heading-alt">12. {_T('Third Parties')}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T('The third parties may assist us in providing our services, and the application release its legal responsibility for any direct or indirect, intentional or unintentional errors made by the third parties which provide services through the application.')}</li>
                <li>{_T('Terms and conditions of third parties may be applied to the user, and these are not subject to our control and therefore the user must access to and agree on these policies before benefiting from the services provided by the third parties through us.')}</li>
              </ul>

              <h4 className="secondary-heading-alt">13. {_T('Intellectual Property Rights')}</h4>

              <p className="text-paragraph mb-3">{_T('The application and all its material and moral elements are privately owned by Tawkeel Commercial Services in the Kingdom of Saudi Arabia, and it should not be imitated, copied or re-used in any way. All the contents of the application (content, lists, texts, images, video, symbols, numbers, letters, icons, buttons, music, data, information) is subject to legal protection under the laws of the Kingdom of Saudi Arabia and international conventions, and We have the right to defend our copyrights by all legal means.')}</p>

              <p className="text-paragraph mb-5">{_T('"Tawkeel" is a trademark used by us and it should not be infringed, imitated, copied, traded illegally or used on marks or services that are not ours, and in case of an attack on that trademark, we have the right to take all the legal procedures that preserves all our commercial rights.')}</p>

              <h4 className="secondary-heading-alt">14. {_T('Legal Responsibility')}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T('Each performer is personally responsible for all the actions and activities which he carries out through the application, and the application will not be subsidiary or solidarity responsible of any users.')}</li>
                <li>{_T('Each performer is personally responsible in case he does not comply with the commitments imposed on him under these applicable terms or policies.')}</li>
                <li>{_T('Each performer is personally responsible in case he violates the laws applicable in the Kingdom of Saudi Arabia or the country to which he belongs, and the application will not be subsidiary or solidarity responsible of any users.')}</li>
                <li>{_T('The performer shall bear the legal responsibility in case that he violates any of our rights under these terms and conditions, attacks on any of our rights, our ownership of the application or any of its elements.')}</li>
                <li>{_T('The performer shall bear the legal responsibility in case of offending the application in any website, application, social media, email or through any other means.')}</li>
                <li>{_T('The performer shall bear the legal responsibility in case of offending of any other person through the application, without original, subsidiary or joint responsibility on the application.')}</li>
                <li>{_T('The performer shall be bound to compensate us for all the damages to the application as a result of any illegitimate or unauthorized use by the user or any of his followers.')}</li>
                <li>{_T('The performer shall be bound to compensate us in case of offending us, the administration of the application, employees, partners, distributors, agents, our affiliates or any other person who belongs to Tawkeel application.')}</li>
                <li>{_T('We reserve all our legal rights arising from this Agreement at any time, and that not using the right at a specific time does not mean to waive it at all other times.')}</li>
              </ul>

              <h4 className="secondary-heading-alt">15. {_T('Disclaimer of Liability')}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T('The user release us from the responsibility for all the illegitimate activities which are not our and may occur through the application, the application cannot control all the actions carried through it, and the affected must inform us to take the necessary actions towards stopping the source of the damage.')}</li>
                <li>{_T('The application may discontinue from time to time and it may permanently discontinue, whether for voluntary or involuntary reasons, and therefore you exempt us from any legal responsibility in case of a permanent or temporary discontinuing of the application or any of its services.')}</li>
                <li>{_T('The application does not provide any type of insurance or compensations to any of its users, and anyone who uses the application and its services at its own responsibility, and the application will not be responsible to face any of the users for any reason resulting from the use of the application or its services or apply our terms and policies.')}</li>
                <li>{_T('The application is not responsible for any comments or content posted through it, or for any communications within or outside the application.')}</li>
                <li>{_T('The performer releases the application\'s responsibility for all errors it makes, whether intentional or unintentional.')}</li>
              </ul>

              <h4 className="secondary-heading-alt">16. {_T('Communications and Notifications')}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T('The application communicates with you from time to time through the contact details you have provided to us, and under this agreement you authorize us to communicate with you online or by telephone, and in case you do not want to receive communication from us you must notify us and we will immediately discontinue to communicate with you, but this means that our services for you may be permanently discontinue.')}</li>
                <li>{_T('Any notifications the application wants to notify the users with are made through their own contact details, and the user is supposed to know about with the notification once the application sends it to him. In case that the user wants to send us notifications, this must be done through our e-mail (support@tawkeel.com)')}</li>
              </ul>

              <h4 className="secondary-heading-alt">17. {_T('Amendments and Additions')}</h4>

              <p className="text-paragraph mb-5">{_T('You know and agree that our services may be continuously amended or updated, and our terms, conditions and privacy policy may be amended, updated or added from time to time, and the application will not be obliged to notify any of its users, so you must review this agreement before any process you make through the application, and accordingly you acknowledge the right of Tawkeel at any time and without prior notice and at its sole discretion to review these terms and conditions or impose new terms and conditions related to Tawkeel You bear the responsibility for periodically reviewing these terms and conditions to review any amendment on these terms and conditions, and any use or obtain of the products or services of Tawkeel constitutes your consent to these reviews or additions.')}</p>

              <h4 className="secondary-heading-alt">18. {_T('Cancellation')}</h4>

              <p className="text-paragraph mb-5">{_T('The application has the right to cancel any of the services available through it, adjust the application completely, change it or change its activity. We also have the right to cancel the terms, conditions and privacy policy or replace them at any time without requiring your consent.')}</p>

              <h4 className="secondary-heading-alt">19. {_T('Privacy and Confidentiality')}</h4>

              <p className="text-paragraph mb-3">{_T('Data collection and processing are subject to the privacy policy approved by the application, which is an integral part of the terms and conditions.')}</p>

              <p className="text-paragraph mb-3">{_T('The user shall be bound to maintain the confidentiality of all internal transactions of the application, all financial reports, commissions, fees, employee\'s data, and any data or documents that specific person are authorized to access to or not prepared for access by others.')}</p>

              <p className="text-paragraph mb-3">{_T('The user shall be bound not to collect or exploit any data of any other parties who use the application, such as clients and service providers, and the use of data is limited to the purposes of providing services or benefiting from them only.')}</p>

              <p className="text-paragraph mb-5">{_T('The application maintains the confidentiality of all data that you provided us with or that related to your activities through the application and is accessed to by our employees and service providers and the third parties relevant to this data or who explicitly authorized by the user or the application to use the data or by virtue of the nature of the transaction itself that allows it.')}</p>


              <h4 className="secondary-heading-alt">20. {_T('Location Permissions and Usage')}</h4>

              <p className="text-paragraph mb-3">{_T('The user shall be bound not to collect or exploit any data of any other parties who use the application, such as clients and service providers, and the use of data is limited to the purposes of providing services or benefiting from them only.')}</p>

              <p className="text-paragraph mb-3">{_T('Our app requires access to your device’s location in order to track your location during Umrah journeys. We collect both coarse and fine location data to provide accurate tracking and navigation services to help you perform Umrah rituals safely and efficiently.')}</p>

              <p className="text-paragraph mb-3">{_T('Why We Need Your Location')}</p>

              <ul className='text-unordered-list mb-3'>
                <li>{_T('Umrah Tracking: We use your location data to track your movements during Umrah journeys, including your current position, route, and points of interest. This information is essential for providing real-time navigation, guidance, and assistance to help you complete your Umrah rituals.')}</li>
              </ul>

              <p className="text-paragraph mb-3">{_T('How We Use Your Location')}</p>

              <ul className='text-unordered-list mb-3'>
                <li>{_T('Navigation: Your location data is used to provide turn-by-turn navigation, route planning, and guidance throughout your Umrah journey. We utilize mapping and GPS technologies to display your current location on maps and provide directions to various landmarks, holy sites, and facilities.')}</li>
                <li>{_T('Safety and Assistance: In case of emergencies or unforeseen circumstances, your location data enables us to provide immediate assistance, support, and guidance to ensure your safety and well-being during Umrah rituals.')}</li>
              </ul>

              <p className="text-paragraph mb-3">{_T('Sharing of Location Data')}</p>

              <ul className='text-unordered-list mb-3'>
                <li>{_T('Third-party Services: We do not share your location data with third-party service providers, such as mapping and navigation platforms. Although we only share the location with the seeker who ordered the umrah and wants to track the umrah performer.')}</li>
              </ul>

              <p className="text-paragraph mb-3">{_T('Your Privacy Rights')}</p>

              <ul className='text-unordered-list mb-5'>
                <li>{_T('Opt-out Options: You have the right to opt-out of location tracking at any time by adjusting the app’s settings or disabling location permissions on your device. However, please note that disabling location tracking may limit the functionality of certain features within the app.')}</li>
              </ul>

              <h4 className="secondary-heading-alt">21. {_T('Law')}</h4>

              <p className="text-paragraph mb-5">{_T('The interpretation and implementation of the items of this document is subject to the laws applicable in the Kingdom of Saudi Arabia, and these terms are not limited to the items contained in it, but extend to include all the legal provisions regulating civil and commercial relations applicable in the Kingdom of Saudi Arabia so far as they were a complementary rule and do not directly or indirectly conflict with the items of this document.')}</p>

              <h4 className="secondary-heading-alt">22. {_T('Jurisdiction')}</h4>

              <p className="text-paragraph mb-5">{_T('* The Saudi judiciary is competent to adjudicate any dispute arising regarding the interpretation or implementation of any clause of this document in accordance with Saudi laws, and in the event that any clause is excluded by a judicial ruling, this does not prejudice the validity of the other clauses and it remains valid and produces its legal effects unless the application cancels the agreement.')}</p>

              <h4 className="secondary-heading-alt">23. {_T('Compensations')}</h4>

              <ul className='text-unordered-list mb-5'>
                <li>{_T('The performer shall be bound to provide the service to the Seeker in the form advertised through the application, and bears legal responsibility in the event of a breach of this obligation, and the Seeker has the right to recourse against the performer legally before all the competent authorities.')}</li>
                <li>{_T('The performer knows and agrees that the application does not provide any kind of insurance or compensation for any damages to the performer during the implementation of the service, and the performer bears all consequences arising from the implementation of the service, this include releases the application\'s responsibility from all damages, fines, accidents, intentional errors, negligence, death, etc.')}</li>
              </ul>

              <h4 className="secondary-heading-alt">24. {_T('Arabic Language')}</h4>

              <p className="text-paragraph mb-5">{_T('The language of the document is Arabic, and if it is translated into any other language, the Arabic text is applicable before all the official and unofficial bodies if the foreign translation conflicts with it.')}</p>

              <h4 className="secondary-heading-alt">25. {_T('Duration and Withdrawal')}</h4>

              <p className="text-paragraph mb-5">{_T('The validity of these terms and conditions will start from the date on which your data is registered via the Tawkeel application or by your use of any of our services that do not require subscription or registration in the application, and they will remain in effect unless terminated in accordance with the items contained in these terms and conditions.')}</p>

              <h4 className="secondary-heading-alt">26. {_T('Contact Us')}</h4>

              <p className="text-paragraph mb-3">{_T('You can contact us through:')}</p>

              <p className="text-paragraph mb-1">{_T('Email:')} support@tawkeel.com</p>

              <p className="text-paragraph mb-1"><span>{_T('Call us on the number')}</span> <span className='d-inline-block escape'>800 244 2203</span> <span>({_T('Toll Free')})</span> <span className='d-inline-block escape'>+966 800 244 2203</span> <span>({_T('International')})</span></p>
            </div>
          </div>

          {createList()}
        </div>
      </section>
      { showFooter && (<Footer language={languages[language]}/>) }
    </>
  );
}

export default Index;
