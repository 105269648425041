import React, { useEffect, useState } from "react";
import logo from '../../assets/images/logo-vertical.png';
import logoLight from '../../assets/images/logo-vertical-light.png';
import qrLight from '../../assets/images/qr-code.png';
import qrDark from '../../assets/images/qr-code-dark.png';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import '../../assets/css/--page-voucher.css';
import divider from '../../assets/images/voucher-divider.png'
import _T from "../../locale/Events";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function Voucher({language}) {
    let params = useParams();
    const navigate = useNavigate();
    const eventId = params.event;
    const typeId = params.type;

    const [phone, setPhone] = useState("+966");
    const baseURL = process.env.REACT_APP_API_BASEURL;
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [voucher, setVoucher] = useState("");
    const [percentage, setPercentage] = useState("");
    const [screen, setScreen] = useState( params.screen && params.screen == "1" ? 1 : 0);
    const [actionLoading, setActionLoading] = useState(false);
    const [errors, setErrors] = useState({ name: '', email: '', phone: '' });
    const isArabic = language === "ar";

    useEffect(()=>{
        localStorage.setItem("lang",language === "ar" ? "ar" : "en")
        setScreen(params.screen && params.screen == "1" ? 1 : 0);
    },[language,params.screen])

    useEffect(()=>{
        if(isArabic){
            document.getElementsByTagName("body")[0].classList.add("RTL");
        }
        else{
            document.getElementsByTagName("body")[0].classList.remove("RTL");
        }
    },[language,params.screen])

    const validateForm = () => {
        const err = { name: '', email: '', phone: '' };
        let isValid = true;
        if (!name.trim()) {
            err.name = _T('Name is required.');
            isValid = false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.trim() || !emailRegex.test(email)) {
            err.email = _T('Please enter a valid email address.');
            isValid = false;
        }
        if (!phone || phone.length < 10) {
            err.phone = _T('Please enter a valid phone number.');
            isValid = false;
        }
        setErrors(err);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setActionLoading(true);
        if (validateForm()) {
            let url = baseURL
            if(language === "ar"){
                url = url.replace("en-us","ar-sa");
            }
            axios.get(`${url}admin/validate-event-user?email=${email}&event_type_id=${typeId}&mobile=${phone}`).then((response) => {
                if (response?.data.status) {
                    axios.post(`${url}admin/add-event-user`,{name: name, email: email, mobile: phone, event_id: eventId }).then((data) => {
                        if (data?.data.status) {
                            setVoucher(data?.data?.data?.code);
                            setPercentage(data?.data?.data?.percentage)
                            setScreen(2);
                        }
                        else{
                            setErrors((prev)=>({...prev, phone: _T("Something went wrong. Please try again!")}))
                            resetForm(false,false);
                        }
                    }).catch((e)=>{
                        setErrors((prev)=>({...prev, phone: _T("Something went wrong. Please try again!")}))
                        resetForm(false,false);
                    })
                }
                else{
                    if(response?.data.statusCode === 2214){
                        setErrors((prev)=>({...prev, phone: _T("Mobile number already exists")}))
                    }
                    else if(response?.data.statusCode === 2215){
                        setErrors((prev)=>({...prev, email: _T("Email address already exists")}))
                    }
                    else{
                        setErrors((prev)=>({...prev, phone: _T("Something went wrong. Please try again!")}))
                    }
                    resetForm(false,false);
                }
            }).catch((e)=>{                
                setErrors((prev)=>({...prev, phone: _T("Something went wrong. Please try again!")}))
                resetForm(false,false);
            })
        }
        else {
            setActionLoading(false);
        }
    };

    const resetForm = (includeErrors = true,resetScreen = true) => {
        setPhone("+966");
        setName("");
        setEmail("");
        setVoucher("");
        setActionLoading(false);
        if(resetScreen){
            setScreen(0);
            navigate(`/en/saudi-expo-2024/${eventId}/${typeId}`)
        }
        if(includeErrors){
            setErrors({ name: '', email: '', phone: '' });
        }
    }

    return (
        screen === 0 ?
            <section id="voucher-section" className="campaign-content campaign-language-selector">
                <div className="voucher-main-container">
                    <form onSubmit={handleSubmit} className="form-container">
                        <div className="form-container-wrapper">
                            <div className="logo">
                                <img src={logo} alt="Tawkeel" />
                            </div>
                            <div className="header">
                                <p>اختر اللغة التي تفضل المتابعة بها</p>
                                <p>Please select your preferred language to continue</p>
                            </div>
                            <div className="footer">
                                <Link to={`/saudi-expo-2024/${eventId}/${typeId}/1`} className="button">اﻟﻌــــﺮﺑـــﻴــﺔ | Arabic</Link>
                                <Link to={`/en/saudi-expo-2024/${eventId}/${typeId}/1`} className="button">اﻹﻧﺠﻠيز ﻳﺔ | English</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </section> :
        screen === 1 ?
            <section id="voucher-section" className="campaign-content campaign-form-selector">
                <div className="voucher-main-container">
                    <div className="logo">
                        <img src={logo} alt="Tawkeel" />
                        <p className="event-sub-title">{_T("Get Umrah Badal Now")}</p>
                    </div>
                    <form onSubmit={handleSubmit} className="form-container">
                        <div className="form-field-wrapper">
                            <div className="form-heading"><h2>{_T('Registration')}</h2></div>
                            <div className="form-input">
                                <div className="form-group">
                                    <span className="d-flex align-items-center">
                                        <span className="dot"></span>
                                        <label>{_T('Name')}</label>
                                    </span>
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                    {errors.name && <p className="error">{errors.name}</p>}
                                </div>
                                <div className="form-group">
                                    <span className="d-flex align-items-center">
                                        <span className="dot"></span>
                                        <label>{_T('Email')}</label>
                                    </span>
                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    {errors.email && <p className="error">{errors.email}</p>}
                                </div>
                                <div className="form-group">
                                    <span className="d-flex align-items-center">
                                        <span className="dot"></span>
                                        <label>{_T('Phone')}</label>
                                    </span>
                                    <div className="phone-number-container escape">
                                        <div className="country-code">
                                            <PhoneInput
                                                country={"sa"}
                                                value={phone}
                                                onChange={(value) => setPhone(value)}
                                                inputStyle={{ backgroundColor: "transparent", outline: "none", width: "100%", border: "none" }}
                                                buttonStyle={{ backgroundColor: "transparent", border: "none", outline: "none" }}
                                            />
                                        </div>
                                    </div>
                                    {errors.phone && <p className="error">{errors.phone}</p>}
                                </div>
                            </div>
                        </div>
                        <button disabled={actionLoading} type="submit" className='main-btn'>{actionLoading ? _T('Please Wait...') : _T('Continue')}</button>
                    </form>
                </div>
            </section> :
        screen === 2 ?
            <section className="campaign-content voucher-content">
                <div id="voucher-code">
                    <div className="logo">
                        <img src={logoLight} alt="Tawkeel" />
                    </div>
                    <div className="voucher-code-container">
                        <div className="voucher-main-image">
                            <p>{_T("Umrah Badal of")} <span className="ltr d-inline-block">{percentage}%</span> {_T("paid")}</p>
                            <img src={divider}/>
                            <h2 className='code-value'>{voucher}</h2>
                        </div>
                        <p className="message">{_T("You will receive an email containing a code for a paid Umrah")} <span className="ltr d-inline-block">({percentage}%)</span> {_T("and a link to download the application")}</p>
                        <button type="button" onClick={()=>{setScreen(3)}} className='finish-btn'>{_T('Confirm')}</button>
                    </div>
                    <div className="QR-Code">
                        <p>{_T("Download application")}</p>
                        <img src={qrLight}/>
                    </div>
                </div>
            </section> :
            <section className="campaign-content voucher-content thankyou-content">
                <div id="voucher-code">
                    <div className="logo">
                        <img src={logoLight} alt="Tawkeel" />
                    </div>
                    <div className="content">
                        <h2>{_T("Thank you")}</h2>
                        <p>{_T("Passage Of Goodness")}</p>
                    </div>
                    <div className="QR-dark-Code">
                        <img src={qrDark}/>
                    </div>
                    <button type="button" onClick={resetForm} className='reset-btn'>{_T('Home Page')}</button>
                </div>
            </section>
    );
}

export default Voucher;
